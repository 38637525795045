import React from "react"
import BasicPage from "../components/PageWrapper/BasicPage"

const Impressum = (props) => {
  return (
    <>
      <BasicPage title="Impressum" location={props.location} noindex={true}>
        <section className="container bg-white py-8 md:px-6 mx-auto max-w-3xl">
          <h2>Kundensupport</h2>
          <p className="my-2">
            Unser Support-Team steht Dir jederzeit gerne für all Deine Fragen
            zur Verfügung. Wir freuen uns auf Deine Anfrage!
            <br />
            E-Mail:
            <br />
            support@smartificate.de
            <br />
            Fax: 040 524 796 499
            <br />
          </p>
          <h2>Kooperationsanfragen</h2>
          <p className="my-2">E-Mail: partnerschaften@smartificate.de</p>
          <h2>Presse</h2>
          <p className="my-2">E-Mail: presse@smartificate.de</p>
          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p className="my-2">
            smartificate GmbH
            <br />
            Schulstraße 28
            <br />
            22113 Oststeinbek
          </p>
          <p className="my-2">
            Handelsregister: HRB 23309 HL
            <br />
            Registergericht: Lübeck
            <br />
            UST-Ident-Nr.: DE348386121
          </p>
          <p className="my-2">
            Vertreten durch die Geschäftsführer: <br />
            Lenz Zuber, Yannic Neubauer
          </p>
          <h2>EU-Streitschlichtung</h2>
          <p>
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <h2>
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </section>
      </BasicPage>
    </>
  )
}

export default Impressum
